import { ExternalLink, Flex, Heading, Paragraph, Strong, Text } from '@dynatrace/strato-components';
//import { PlusIcon } from '@dynatrace/strato-icons';
//import { useState } from 'react';

export const EmptyImagesByType = {
  processor: {
    light: './assets/empty_state_proc_light.svg',
    dark: './assets/empty_state_proc_dark.svg',
  },
  tab: {
    light: './assets/empty_state_light.svg',
    dark: './assets/empty_state_dark.svg',
  },
  chart: {
    light: './assets/empty_chart_light.svg',
    dark: './assets/empty_chart_dark.svg',
  },
};

export interface EmptyStateComponentProps {
  header: string;
  message: string;
  type: 'tab' | 'processor' | 'chart';
  linkURL?: string;
  linkValue?: string;
  buttonValue?: string;
  boldedMessage?: string;
  messageSecondPart?: string;
}

export const EmptyStateComponent = ({
  header,
  message,
  type,
  linkURL,
  linkValue,
  buttonValue,
  boldedMessage,
  messageSecondPart,
}: EmptyStateComponentProps) => {

  return (
    <Flex flexDirection='row' justifyContent='center' width='100%'>
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        style={type === 'tab' ? { marginTop: '20vh' } : {}}
      >
        <Heading level={3}>{header}</Heading>
        {boldedMessage && messageSecondPart ? (
          <Paragraph>
            {message}
            <Strong>{boldedMessage}</Strong>
            {messageSecondPart}
          </Paragraph>
        ) : (
          <Text aria-setsize={14}>{message}</Text>
        )}

        {type === 'tab' ? (
          <Flex flexDirection='row' marginTop={16} justifyContent='center' alignItems='center'/>
        ) : null}
        {type === 'processor' && linkURL ? (
          <>
            <ExternalLink href={linkURL}>{linkValue}</ExternalLink>
          </>
        ) : null}
      </Flex>
    </Flex>
  );
};
