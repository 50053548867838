import { Flex } from '@dynatrace/strato-components/layouts';
import { Button, Text } from '@dynatrace/strato-components';
import { AppHeader, Menu } from '@dynatrace/strato-components-preview';
import { Zyq } from './graphics';
import { DarkmodeIcon, LightmodeIcon } from '@dynatrace/strato-icons';
import { useEffect, useState } from 'react';
import { setLocalMinervaBrowserTheme } from '../../utils/Utilities';

export interface LandingHeaderProps {
  tenantName: string;
}

export const LandingHeader = (props: LandingHeaderProps) => {
  const { tenantName } = props;

  const [darkMode, setDarkMode] = useState<boolean>(document.documentElement.getAttribute('data-theme') === 'dark');

  useEffect(() => {
    setLocalMinervaBrowserTheme(darkMode ? 'dark' : 'light');
  }, [darkMode]);

  return (
    <Flex flexDirection='row' justifyContent='space-between' alignItems='center' width='100%'>
      {/* Left Section: Minerva and Tagline */}
      <Flex flexDirection='column' alignItems='flex-start'>
        <Flex flexDirection='row' gap={8} alignItems='center'>
          <AppHeader.AppIcon src={Zyq} aria-label='Minerva' style={{ width: '32px', height: '32px' }} />
          <Text as='h2'>Minerva</Text>
        </Flex>
      </Flex>

      {/* Center Section: Request Name */}
      <Text as='h3' style={{ textAlign: 'center' }}>
        {`${tenantName} - Secure Client Tasks List`}
      </Text>

      {/* Right Section: Help Button */}
      <Flex flexDirection='row' gap={8} alignItems='flex-end'>
        <Menu>
          <Menu.Trigger>
            <AppHeader.ActionButton
              title={darkMode ? 'Light mode' : 'Dark mode'}
              onClick={() => setDarkMode(!darkMode)}
            >
              {darkMode ? <LightmodeIcon /> : <DarkmodeIcon />}
            </AppHeader.ActionButton>
          </Menu.Trigger>
        </Menu>

        {/* <Button variant='default'>Help</Button> */}
      </Flex>
    </Flex>
  );
};
