import { SelectV2 } from '@dynatrace/strato-components-preview/forms';
import { Flex } from '@dynatrace/strato-components/layouts';
import { format, parseISO } from 'date-fns';
import { StatusDetails } from '../../../../types/Status';

// table main columns
export const columns = [
  {
    id: 'projectRequestId',
    header: 'Request Id',
    accessor: 'projectRequestId',
    autoWidth: true,
    columnType: 'number',
  },
  {
    id: 'projectRequestName',
    header: 'Request name',
    accessor: 'projectRequestName',
    width: 280,
  },
  {
    id: 'createdOn',
    header: 'Created on',
    accessor: 'createdOn',
    columnType: 'date',
    width: 200,
    Cell: ({ value }: any) => {
      try {
        const date = parseISO(value);
        return format(date, 'MMM dd, yyyy HH:mm');
      } catch (error) {
        return 'Invalid date';
      }
    },
  },
  {
    id: 'assignedCount',
    header: 'Receipients count',
    accessor: 'assignedCount',
    autoWidth: true,
    columnType: 'number',
  },
];

// table sub-row columns
export const rowColumns = [
  {
    id: 'assignedto',
    header: 'Assigned to',
    accessor: 'assignedto',
    minWidth: 300,
    columnType: 'text',
  },
  {
    id: 'completed',
    header: 'Completed',
    accessor: 'completed',
    width: 100,
    columnType: 'number',
  },
  {
    id: 'inProgress',
    header: 'In-complete',
    accessor: 'inProgress',
    width: 100,
    columnType: 'number',
  },
];

export function getRowDocumentColumns(statusOptions: StatusDetails[], handleSelectChange: any) {


  const rowDocumentsColumns = [
    {
      id: 'label',
      header: 'File name',
      accessor: 'label',
      width: 200,
    },
    {
      id: 'instructions',
      header: 'Instructions',
      accessor: 'instructions',
      minWidth: 300,
    },
    {
      id: 'statusName',
      header: 'Status',
      accessor: 'statusName',
      width: 150,
    },
  ];

  return rowDocumentsColumns;
}