import { Flex } from '@dynatrace/strato-components/layouts';
import { Heading } from '@dynatrace/strato-components/typography';
import { EmptyState } from '@dynatrace/strato-components-preview/content';
import { Label } from '@dynatrace/strato-components-preview';

export interface ErrorPageProps {
  message?: string;
}

export const ErrorPage = (props: ErrorPageProps) => {
  const { message } = props;

  return (
    <Flex alignItems='center' justifyContent='center' height='80%'>
      <Flex flexDirection='column' alignItems='center'>
        <EmptyState size='large'>
          <EmptyState.Visual>
            <EmptyState.VisualPreset context='document' type='no-result' />
          </EmptyState.Visual>
        </EmptyState>
        <Flex flexDirection='column' alignItems='center' paddingTop={8} gap={6}>
          <Heading level={3}>Page not found</Heading>
          You have reached a page that does not exist.
          {message && <Label>({message})</Label>}
        </Flex>
      </Flex>
    </Flex>
  );
};
