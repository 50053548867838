import { AxiosError } from 'axios';
import { getMinervaUserToken } from '../utils/Utilities';

export interface ApiResponse {
  data: any;
}

export const handleApiError = (error: AxiosError) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('API No Response:', error.request);
  } else {
    console.error('API Error:', error.message);
  }
};

export function getBaseURL(): string {
  const url = window.minervaRuntime.apiURL;
  if (url === undefined) {
    console.warn('API URL is not defined');
  }   
  return url;
}

export function getAuthHeader(): any {
  return { headers: { Authorization: 'Bearer ' + getMinervaUserToken() } };
}
