import { ProgressCircle } from '@dynatrace/strato-components';
import { hideLoading, showLoading } from './LoaderStyles.css';

export interface LoadingStateComponentProps {
  loading: boolean;
}

export const LoadingStateComponent = (props: LoadingStateComponentProps) => {
  const {loading} = props;

  return (
    <ProgressCircle
      className={loading ? showLoading : hideLoading}
      value={'indeterminate'}
      size={'large'}
      max={100}
      variant={'primary'}
      aria-label='Loading'
    />
  );
};
