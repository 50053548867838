import { AppRoot } from '@dynatrace/strato-components';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { getMinervaBrowserTheme } from './app/utils/Utilities';
import { AppContextProvider } from './app/contexts/AppContext';

///TODO: move this a declaration module
// this is to handle global runtime variables that are missing for the app.
declare global {
  interface Window {
    dtRuntime: {
      userPreferences: {
        getTimezone: () => void;
        getTheme: () => void;
      };
      appEnvironment: {
        getAppId: () => void;
        getAppName: () => void;
      };
    };
    minervaRuntime: {
      apiURL: string;
      isDev: boolean;
      auth0Domin: string;
      auth0ClientId: string;
    };
  }
}

window.dtRuntime = window.dtRuntime || {
  userPreferences: {
    getTimezone: () => {
      return 'UTC';
    },
    getTheme: () => {
      return getMinervaBrowserTheme();
    },
    getAppId: () => {
      return 'minerva';
    },
    getAppName: () => {
      return 'Minerva';
    },
    getLanguage: () => {
      return 'en';
    },
    getRegionalFormat: () => {
      return 'en-US';
    },
  },
  appEnvironment: {
    getAppId: () => {
      return 'minerva';
    },
    getAppName: () => {
      return 'Minerva';
    },
  },
};
window.minervaRuntime = window.minervaRuntime || {
  isDev: process.env.REACT_APP_ENVIRONMENT === 'dev',
  auth0Domin: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  apiURL: process.env.REACT_APP_API_URL,
};
console.warn('connecting to:', window.minervaRuntime.auth0Domin, window.minervaRuntime.apiURL, window.minervaRuntime.auth0ClientId);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={window.minervaRuntime.auth0Domin}
      clientId={window.minervaRuntime.auth0ClientId}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <AppRoot>
        <BrowserRouter basename='/'>
          <AppContextProvider appInfo={{}}>
            <App />
          </AppContextProvider>
        </BrowserRouter>
      </AppRoot>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
