import { Dispatch, SetStateAction } from 'react';
import { Request, UploadRequestDetails } from '../../types/Request';
import { FileFormat, FileNameState } from '../../types/File';
import { UploadService } from '../../services/UploadService';

export interface UploadFileKeys {
  token: string;
  fileFormat: FileFormat[] | undefined;
  projectId: number | undefined;
  peopleId: number | undefined;
  request: Request | undefined;
  tenantId: number | undefined;
}

export function notApplicableCheck(
  task: UploadRequestDetails,
  isChecked: boolean,
  setInputValue: Dispatch<
    SetStateAction<{
      [key: string]: {
        Status: string;
        comments: string;
      };
    }>
  >,
  setFileDataMap: Dispatch<
    SetStateAction<{
      [key: string]: {
        files: File[];
        details: any;
        fileExt: string;
      };
    }>
  >,
  setFileExtValid: Dispatch<SetStateAction<{ [key: number]: boolean }>>,
) {
  if (isChecked) {
    setInputValue((prev) => ({
      ...prev,
      [task.projectRequestSentId]: {
        ...prev[task.projectRequestSentId], // Ensure the task-specific state is preserved
        Status: isChecked ? 'Not Applicable' : 'New',
      },
    }));
    setFileDataMap((prevMap) => ({
      ...prevMap,
      [task.projectRequestSentId]: {
        files: [null],
        details: {
          ...task,
          statusName: 'Not Applicable',
        },
        fileExt: null,
      },
    }));
    setFileExtValid((prevChecks) => ({
      ...prevChecks,
      [task.projectRequestSentId]: false,
    }));
  }
  else {
    setInputValue((prev) => {
      const newPrev = { ...prev };  // Create a shallow copy of the previous state
      delete newPrev[task.projectRequestSentId];  // Delete the specific task entry
      return newPrev;  // Return the updated state
    });
    setFileDataMap((prevMap) => ({
      ...prevMap,
      [task.projectRequestSentId]: {
        files: [null],
        details: {
          ...task,
          statusName: 'New',
        },
        fileExt: null,
      },
    }));
    setFileExtValid((prevFileChecks) => {
      const updatedFileCheckState = { ...prevFileChecks };
      // Remove the file data from fileName state
      if (updatedFileCheckState.hasOwnProperty(task.projectRequestSentId)) {
        delete updatedFileCheckState[task.projectRequestSentId];
      }

      return updatedFileCheckState;
    });
  }
}

export function handleCommentsRemove(
  task: UploadRequestDetails,
  setInputValue: Dispatch<
    SetStateAction<{
      [key: string]: {
        Status: string;
        comments: string;
      };
    }>
  >,
  setBackupComments: Dispatch<SetStateAction<{ [key: string]: string }>>

) {
  setBackupComments((prevInputValue) => {
    const updatedInputValue = { ...prevInputValue };
    if (updatedInputValue[task.projectRequestSentId]) {
      delete updatedInputValue[task.projectRequestSentId];
    }

    return updatedInputValue;
  });
  setInputValue((prevInputValue) => {
    const updatedInputValue = { ...prevInputValue };
    if (updatedInputValue[task.projectRequestSentId]) {
      delete updatedInputValue[task.projectRequestSentId];
    }

    return updatedInputValue;
  });
  setInputValue((prevInputValue) => ({
    ...prevInputValue,
    [task.projectRequestSentId]: {
      Status: "Not Applicable",
    },
  }));
}
export function handleUploadFileChange(
  event: React.ChangeEvent<HTMLInputElement>,
  requestDetails: UploadRequestDetails,
  setFileDataMap: Dispatch<
    SetStateAction<{
      [key: string]: {
        files: File[];
        details: any;
        fileExt: string;
      };
    }>
  >,
  setFileName: Dispatch<SetStateAction<FileNameState>>,
  setFileExtValid: Dispatch<SetStateAction<{ [key: number]: boolean }>>,
) {
  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'text/plain', // .txt
    'application/zip', // .zip
    'application/x-zip-compressed'
  ];

  const files = event.target.files;
  if (files) {
    const fileURL = URL.createObjectURL(files[files.length - 1]);
    const fileArray = Array.from(files);
    const newFileName = fileArray[fileArray.length - 1].name; // Get the name of the last selected file
    const newFileSize = fileArray[fileArray.length - 1].size;
    const newFileExt = fileArray[fileArray.length - 1].name.split('.')[1];
    const isValid = allowedFileTypes.includes(fileArray[fileArray.length - 1].type);
    // Update fileDataMap state
    setFileDataMap((prevMap) => ({
      ...prevMap,
      [requestDetails.projectRequestSentId]: {
        files: [...(prevMap[requestDetails.projectRequestSentId]?.files || []), ...fileArray],
        details: requestDetails,
        fileExt: newFileExt,
      },
    }));

    // Update fileName state
    setFileName((prevFiles) => ({
      ...prevFiles,
      [requestDetails.projectRequestSentId]: {
        name: newFileName,
        file: fileURL,
        size: newFileSize,
        ext: newFileExt,
      },
    }));

    setFileExtValid((prevChecks) => ({
      ...prevChecks,
      [requestDetails.projectRequestSentId]: isValid,
    }));
  }
}

export const handleUploadFileRemove = (
  documentTypeAutoId: number,
  projectRequestSentId: number,
  setFileDataMap: Dispatch<
    SetStateAction<{
      [key: string]: {
        files: File[];
        details: any;
        fileExt: string;
      };
    }>
  >,
  setFileName: Dispatch<SetStateAction<FileNameState>>,
  setFileExtValid: Dispatch<SetStateAction<{ [key: number]: boolean }>>,
) => {
  setFileDataMap((prevFileDataMap) => {
    const newFileDataMap = { ...prevFileDataMap };
    delete newFileDataMap[projectRequestSentId];
    return newFileDataMap;
  });

  setFileName((prevFileName) => {
    const updatedFileNameState = { ...prevFileName };

    // Remove the file data from fileName state
    if (updatedFileNameState[projectRequestSentId]) {
      delete updatedFileNameState[projectRequestSentId];
    }

    return updatedFileNameState;
  });
  setFileExtValid((prevFileChecks) => {
    const updatedFileCheckState = { ...prevFileChecks };

    // Remove the file data from fileName state
    if (updatedFileCheckState.hasOwnProperty(projectRequestSentId)) {
      delete updatedFileCheckState[projectRequestSentId];
    }

    return updatedFileCheckState;
  });
};

const getFileDataUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error('Failed to read file'));
      }
    };
    reader.onerror = () => reject(new Error('Error reading file'));
    reader.readAsDataURL(file);
  });
};

export const notApplicableStatusUpdate = async (

  projectRequest: any,
  tenantId: any,
  projectId: any,
  peopelId: any,
  token: any,
  details: any,
) => {
  const params = {
    token,
    tenantId,
    projectId,
    projectRequestId: projectRequest?.projectRequestId,
    projectRequestSentId: details.projectRequestSentId,
    peopelId,
    documentTypeAutoId: details.documentTypeAutoId,
    statusName: details.statusName,
    comments: details.comments,
  };

  await UploadService.postProjectRequestNotApplicableUpdate(params);
};

export const blobToData = async (
  file: File,
  index: any,
  projectRequest: any,
  tenantId: any,
  projectId: any,
  peopelId: any,
  fileFormt: any,
  token: any,
  details: any,
) => {
  const fileDataUrl = await getFileDataUrl(file);
  const base64Data = fileDataUrl.split(',')[1];
  // const mimeTypeSegment = fileDataUrl.split(',')[0];
  // const mimeType = mimeTypeSegment.split(':')[1].split(';')[0];
  const fileext = file.name.split('.').pop();
  // const fileext = fileFormt[0]?.fileTypeName;

  const params = {
    token,
    tenantId,
    projectId,
    projectRequestId: projectRequest?.projectRequestId,
    projectRequestSentId: details.projectRequestSentId,
    peopelId,
    documentTypeAutoId: details.documentTypeAutoId,
    fileData: base64Data,
    fileext: '.' + fileext,
    fileName: `${file.name.split('.').slice(0, -1).join('.')}`,
  };

  await UploadService.postProjectRequestUploadFiles(params);
};
