import { Flex } from '@dynatrace/strato-components/layouts';
import { UploadRequestDetails } from '../../types/Request';
import { IncompleteTasks } from './IncompleteTasks';
import { CompletedTasks } from './CompletedTasks';
import { UploadFileKeys } from './upload-utils';
import { RequestDetails } from './RequestDetails';

export interface MainContentProps {
  incompleteTasks: UploadRequestDetails[];
  completedTasks: UploadRequestDetails[];
  toggleExpand: (projectRequestSentId: number) => void;
  expandedSections: { [key: number]: boolean };
  reloadUploadDetails: () => void;
  uploadFileKeys: UploadFileKeys;
}

export const MainContent = (props: MainContentProps) => {
  const {
    incompleteTasks,
    completedTasks,
    toggleExpand,
    expandedSections,
    reloadUploadDetails,
    uploadFileKeys,
  } = props;
  return (
    <Flex flexDirection='column' gap={16}>
      {/* Request Details Section */}
      <RequestDetails request={uploadFileKeys.request}/>
      {/* Incomplete Tasks Section */}
      {incompleteTasks && incompleteTasks.length > 0 && (
        <IncompleteTasks
          incompleteTasks={incompleteTasks}
          toggleExpand={toggleExpand}
          expandedSections={expandedSections}
          reloadUploadDetails={reloadUploadDetails}
          uploadFileKeys={uploadFileKeys}
        />
      )}

      {/* Completed Tasks Section */}
      {completedTasks && completedTasks.length > 0 && (
        <CompletedTasks completedTasks={completedTasks} toggleExpand={toggleExpand} />
      )}
    </Flex>
  );
};
