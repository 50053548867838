import { showToast } from '@dynatrace/strato-components-preview';
import { Container } from '@dynatrace/strato-components';
import diamondLight from  './../../assets/diamond_light.svg';
import diamondDark from  './../../assets/diamond_dark.svg';
import { getMinervaBrowserTheme } from './Utilities';

export function ShowErrorNotification(errorMain: string, errorDetail?: any | string) {
  showToast({
    title: 'Error Notification',
    type: 'critical',
    role: 'alert',
    message: `${errorMain} (${errorDetail})`,
    position: 'bottom-center',
    lifespan: 2000,
  });
}

export function ShowSuccessNotification(successMain: string) {
  showToast({
    title: 'Success Notification',
    type: 'success',
    role: 'alert',
    message: `${successMain}`,
    position: 'bottom-center',
    lifespan: 2000,
  });
}

export function ShowValidationBanner(errorMain: string, errorDetail?: any | string) {
  const errorImageAsset =
    getMinervaBrowserTheme() === 'light' ? diamondLight : diamondDark;

  return (
    <Container marginTop={8} marginBottom={8} color='critical'>
      <img src={errorImageAsset} alt={'Error'} height='12px' width='12px' />
      {` `}
      {`${errorMain}: ${errorDetail}.`}
    </Container>
  );
}

export function ShowInvalidExtensionValidationBanner(errorMain: string) {
  const errorImageAsset =
    getMinervaBrowserTheme() === 'light' ? diamondLight : diamondDark;

  return (
    <Container marginTop={8} marginBottom={8} color='critical'>
      <img src={errorImageAsset} alt={'Error'} height='12px' width='12px' />
      {` `}
      {`${errorMain}.`}
    </Container>
  );
}
