import { Label } from '@dynatrace/strato-components-preview/forms';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Strong, Text } from '@dynatrace/strato-components/typography';
import { Project, ProjectBusinessRelation, ProjectPeopleRelation } from '../../../types/Project';
import { useState } from 'react';
import { truncateText } from './project-details-utils';
import { Colors } from '@dynatrace/strato-design-tokens';
import { ItemInfo } from '../../../types/ListItemInfo';
import { Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { AvatarGroup } from '@dynatrace/strato-components-preview/content';
import { EditIcon, HomeIcon } from '@dynatrace/strato-icons';
import { format, parseISO } from 'date-fns';
import { ProjectSheet } from '../ProjectSheet';
import { Page } from '@dynatrace/strato-components-preview/layouts';
import { StatusDetails } from '../../../types/Status';
import { LoanTypeDetails } from '../../../types/Loan';

export interface ProjectDetailsProps {
  project: Project | undefined;
  projectStatus: StatusDetails | undefined;
  projectLoan: LoanTypeDetails | undefined;
  projectPeopleRelation: ProjectPeopleRelation[];
  projectBusinessesRelation: ProjectBusinessRelation[];
  refetchProjectDetails: () => void;
  onSidebarDismissChange: () => void;
}

export const ProjectDetails = (props: ProjectDetailsProps) => {
  const {
    project,
    projectStatus,
    projectLoan,
    projectPeopleRelation,
    projectBusinessesRelation,
    refetchProjectDetails,
    onSidebarDismissChange,
  } = props;

  const [showFullText, setShowFullText] = useState(false);
  const [editDetailsVisible, setEditDetailsVisible] = useState<ItemInfo | undefined>();

  const editProjectSheet = () => {
    setEditDetailsVisible({
      id: project?.projectId ?? 0,
      name: project?.projectName,
      visibility: true,
    });
  };

  return (
    <Flex flexDirection='column' gap={32}>
      <Flex flexDirection='row' justifyContent='space-between'>
        <Flex justifyContent='flex-start'>
          <Strong>
            <Label style={{ fontSize: '16px' }}>Project information</Label>
          </Strong>
        </Flex>
        <Flex justifyContent='flex-end'>
          <Tooltip text={'Edit Project Info'}>
            <Button color='primary' onClick={editProjectSheet} width={'70px'} variant='accent'>
              <Button.Prefix>
                <EditIcon size={'small'} />
              </Button.Prefix>
              Edit
            </Button>
          </Tooltip>
          <Page.PanelControlButton onClick={onSidebarDismissChange} target='sidebar' />
        </Flex>
      </Flex>
      <Flex flexDirection='column' gap={12}>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Project description</Label>
          <Text style={{ color: Colors.Text.Neutral.Default, whiteSpace: 'pre-wrap' }}>
            {showFullText ? project?.projectDescription : truncateText(project?.projectDescription || '', 1)}
            {!showFullText && truncateText(project?.projectDescription || '', 1) !== project?.projectDescription && (
              <Button
                variant='default'
                color='neutral'
                onClick={() => setShowFullText(true)}
                style={{ marginTop: '-10px', fontSize: '11px' }}
              >
                <u>Expand</u>
              </Button>
            )}
            {showFullText && (
              <Button
                variant='default'
                color='neutral'
                onClick={() => setShowFullText(false)}
                style={{ marginTop: '-10px', fontSize: '11px' }}
              >
                <u>Collapse</u>
              </Button>
            )}
          </Text>
        </Flex>

        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Status</Label>
          <Text style={{ color: Colors.Text.Neutral.Default }}> {projectStatus?.statusName}</Text>
        </Flex>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Loan amount</Label>
          <Text style={{ color: Colors.Text.Neutral.Default }}>
            <b>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(parseInt(project?.amount ?? '', 10))}
            </b>
          </Text>
        </Flex>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Use of funds</Label>
          <Text style={{ color: Colors.Text.Neutral.Default }}>{project?.purpose}</Text>
        </Flex>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Assigned staff</Label>
          <Text style={{ color: Colors.Text.Neutral.Default }}>{project?.assignedToName}</Text>
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>
            Primary borrower
          </Label>
          {projectPeopleRelation && projectPeopleRelation.length > 0 && (
            <Flex flexDirection="row">
              <Tooltip text={projectPeopleRelation[0]?.lastName + ', ' + projectPeopleRelation[0]?.firstName}>
                <Text style={{ color: Colors.Text.Neutral.Default }}>{projectPeopleRelation[0]?.lastName + ' ' + projectPeopleRelation[0]?.firstName} </Text>
              </Tooltip>
            </Flex>
          )}
        </Flex>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Sponsors</Label>
          <Flex flexDirection='row'>
            {projectPeopleRelation.length === 2 ?
              <Text style={{ color: Colors.Text.Neutral.Default }}>{projectPeopleRelation[1]?.lastName + ' ' + projectPeopleRelation[1]?.firstName}</Text>
              :
              <AvatarGroup>
                {projectPeopleRelation?.map(
                  (person, index) =>
                    index !== 0 && (
                      <AvatarGroup.Item
                        abbreviation={
                          (person?.lastName?.[0]?.toUpperCase() || '') + (person?.firstName?.[0]?.toUpperCase() || '')
                        }
                        tooltipText={`${person?.lastName}, ${person?.firstName}`}
                        key={`${person?.lastName}, ${person?.firstName}`}
                      />
                    ),
                )}
              </AvatarGroup>
            }

          </Flex>
        </Flex>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Primary business</Label>
          <Flex flexDirection='row'>
            {projectBusinessesRelation !== undefined && projectBusinessesRelation?.length > 0
              ? <HomeIcon />
              : ''}
            <Text style={{ color: Colors.Text.Neutral.Default }}>
              {projectBusinessesRelation !== undefined && projectBusinessesRelation?.length > 0
                ? projectBusinessesRelation[0]?.businessName
                : ''}
            </Text>
          </Flex>
        </Flex>
        {
          //MSD-68: Drop this field from the project.
          //<Flex flexDirection='column' gap={2}>
          // <Label style={{ color: Colors.Text.Primary.Disabled }}>Industry Sector:</Label>
          // <Text style={{ color: Colors.Text.Neutral.Default }}>
          //   {' '}
          //   {projectDetailsData?.industry != null ? projectDetailsData?.industry?.industrySector : 'N/A'}
          // </Text>
          //</Flex>
        }
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Loan type</Label>
          <Text style={{ color: Colors.Text.Neutral.Default }}>{projectLoan?.loanType}</Text>
        </Flex>
        <Flex flexDirection='column' gap={2}>
          <Label style={{ color: Colors.Text.Primary.Disabled }}>Desired closing date
          </Label>
          <Text style={{ color: Colors.Text.Neutral.Default }}>
            {project?.desiredClosedDate ? format(parseISO(project?.desiredClosedDate), 'MMM dd, yyyy') : ''}
          </Text>
        </Flex>
      </Flex>
      {editDetailsVisible && editDetailsVisible.visibility && (
        <ProjectSheet
          closeDetails={(itemInfo?: ItemInfo) => {
            //reload the page as needed
            setEditDetailsVisible(itemInfo);
            refetchProjectDetails();
          }}
          show
          itemInfo={editDetailsVisible}
        />
      )}
    </Flex>
  );
};
