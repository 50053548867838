import { Colors } from "@dynatrace/strato-design-tokens";
import { UploadIcon, SuccessIcon, CriticalIcon, DeclineIcon } from "@dynatrace/strato-icons";

export const StatusConfig = {
    New: {
      backgroundColor: Colors.Background.Container.Neutral.Default,
      color: Colors.Text.Neutral.DefaultActive,
      icon: <UploadIcon />,
    },
    'Not Applicable': {
      backgroundColor: Colors.Background.Container.Neutral.Default,
      color: Colors.Text.Neutral.DefaultActive,
      icon: <UploadIcon />,
    },
    Approved: {
      backgroundColor: Colors.Background.Container.Success.Default,
      color: Colors.Text.Success.Default,
      icon: <SuccessIcon />,
    },
    Acknowledged: {
      backgroundColor: Colors.Background.Container.Neutral.Accent,
      color: Colors.Text.Neutral.OnAccent.Default,
      icon: <CriticalIcon />,
    },
    Completed: {
      backgroundColor: Colors.Background.Container.Success.Emphasized,
      color: Colors.Text.Success.OnAccent.Default,
      icon: <SuccessIcon />,
    },
    Rejected: {
      backgroundColor: Colors.Background.Container.Critical.Default,
      color: Colors.Text.Critical.Default,
      icon: <DeclineIcon />,
    },
    'Pending Approval': {
      backgroundColor: Colors.Background.Container.Neutral.Default,
      color: Colors.Text.Neutral.Default,
      icon: <SuccessIcon />,
    },
  };
