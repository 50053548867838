import {
  DataTable,
  FilterBar,
  Sheet,
  TableColumn,
  TextInput,
  TitleBar,
} from '@dynatrace/strato-components-preview';
import { Button, Flex, TextEllipsis } from '@dynatrace/strato-components';
import { useMemo, useState } from 'react';
import { XmarkIcon, PlusIcon } from '@dynatrace/strato-icons';
import { ItemInfo } from '../../types/ListItemInfo';
import { TenantSheet } from './TenantSheet';
import { useTenants } from '../../hooks/use-minerva-data';
import { ShowErrorNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';

export interface TenantsProps {
  itemInfo?: ItemInfo;
  close: () => void;
  show: boolean;
}

export const TenantsSheet = (props: TenantsProps) => {
  const { close, show } = props;

  const [showTenants, setShowTenants] = useState(show);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo | undefined>();

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'tenantName',
        header: 'Tenant Name',
        accessor: 'tenantName',
        width: 300,
        cell: (cell: any) => (
          <DataTable.Cell>
            <TextEllipsis
              onClick={() => {
                setItemDetailsVisible({
                  id: cell.row.original.tenantId,
                  index: cell.row.index,
                  name: cell.row.original.tenantName,
                  visibility: true,
                });
              }}
              truncationMode='end'
              style={{
                color: 'var(--dt-colors-text-primary-default',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              {cell.row.original.tenantName}
            </TextEllipsis>
          </DataTable.Cell>
        ),
      },
      {
        id: 'tenantContactName',
        header: 'Contact Name',
        accessor: 'tenantContactName',
        width: 200,
      },
      {
        id: 'tenantDescription',
        header: 'Description',
        accessor: 'tenantDescription',
      },
    ],
    [],
  );

  const { isLoading, data: tenants, error, refetch } = useTenants();
  if (error) {
    ShowErrorNotification('Error loading tenants', error);
  }

  const filteredData = useMemo(() => {
    //console.log('Tenants:', tenants);
    return searchQuery && searchQuery.trim().length !== 0
      ? tenants?.filter(
          (tenant) =>
            tenant.tenantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.tenantDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.tenantContactName.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : tenants;
  }, [tenants, searchQuery]);

  // discarded or closed the sheet from escape key
  const dismissTenants = () => {
    close();
  };

  return (
    <Sheet show={showTenants} onDismiss={dismissTenants} style={{ height: '100%' }}>
      <Flex flexDirection='column' margin={8} gap={8}>
        <TitleBar>
          <TitleBar.Title>Tenants</TitleBar.Title>
          <TitleBar.Subtitle>View and manage your tenants </TitleBar.Subtitle>
          <TitleBar.Action>
            <Flex flexDirection='row' gap={8}>
              <Button
                width='75px'
                onClick={() => {
                  setShowTenants(false);
                  dismissTenants();
                }}
                variant='default'
              >
                <Button.Prefix>
                  <XmarkIcon />
                </Button.Prefix>
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>

        <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginBottom={16} marginTop={8}>
          <FilterBar
            onFilterChange={() => {
              //console.log('Filter changed!');
            }}
          >
            <FilterBar.Item name={'search'} label={''}>
              <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
            </FilterBar.Item>
            <FilterBar.Item name={'selectedType'} label={''}>
              <Button></Button>
            </FilterBar.Item>
          </FilterBar> 

          <Button
            onClick={() => {
              setItemDetailsVisible({ visibility: true });
            }}
            variant='accent'
            color='primary'
            style={{ margin: '0px 0px 0px auto' }}
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            Tenant
          </Button>
        </Flex>
      </Flex>
      <Flex flexDirection='column' margin={8} padding={0} gap={8}>
        <LoadingStateComponent loading={isLoading} />
        <DataTable
          loading={isLoading}
          columns={columns}
          data={filteredData ?? tenants ?? []}
          sortable
          variant={{ rowDensity: 'default' }}
          sortBy={{ id: 'tenantName', desc: false }}
        ></DataTable>

        {itemDetailsVisible && itemDetailsVisible.visibility && (
          <TenantSheet
            closeDetails={(itemInfo?: ItemInfo) => {
              //reload the page as needed
              setItemDetailsVisible(itemInfo);
              if (itemInfo?.refreshParent) {
                //reload the page
                refetch();
              }
            }}
            show
            itemInfo={itemDetailsVisible}
          />
        )}
      </Flex>
    </Sheet>
  );
};
